import React from 'react'
import ImportTokenModal from '../components/common/ImportToken'

const TokenImport = () => {
  return (
    <div>
        <ImportTokenModal />
    </div>
  )
}

export default TokenImport